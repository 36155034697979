<template>
    <div class="p_item d_flex">
        <div
            style="background-size: cover; height: 160px"
            :class="
                info.type == 'card'
                    ? `img_wrapper orange-background`
                    : `img_wrapper green-background`
            "
        >
            <div
                style="
                    width: 100%;
                    text-align: right;
                    padding-right: 20px;
                    padding-top: 20px;
                    margin-bottom: 20px;
                "
            >
                <img
                    style="width: 50px; float: left; margin-left: 20px"
                    src="../assets/images/logo_L.png"
                />
                <p class="info-value" style="margin-bottom: 8px">
                    累積期限：{{ info.e_date }}
                </p>
                <p class="info-value">兌換期限：{{ info.ex_date }}</p>
            </div>
            <div class="row" style="padding-bottom: 20px">
                <div class="col-4" style="padding-right: 20px">
                    <p class="info-value">消費金額</p>
                    <p class="value">
                        <img src="../assets/images/money.png" />{{
                            info.amount
                        }}
                    </p>
                </div>
                <div class="col-4" style="padding-right: 20px">
                    <p class="info-value">累積點數</p>
                    <p class="value">
                        <img src="../assets/images/foot_active.png" />{{
                            info.ac_point
                        }}<label class="info-value">點</label>
                    </p>
                </div>
                <div class="col-4" style="padding-right: 20px">
                    <p class="info-value">剩餘點數</p>
                    <p class="value">
                        <img src="../assets/images/foot_active.png" />{{
                            info.rm_point
                        }}<label class="info-value">點</label>
                    </p>
                </div>
            </div>
        </div>
        <div class="detail_wrapper d_flex">
            <h5
                :style="
                    info.type == 'card' ? 'color:#FF9F48;' : 'color:#808184;'
                "
                @click="
                    showPopup
                        ? togglePopup()
                        : $router.push(
                              '/point/coupon?id=' +
                                  info.id +
                                  '&point=' +
                                  info.rm_point
                          )
                "
            >
                兌換優惠卷
            </h5>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PointCardItem',
    props: {
        info: Object,
        showPopup: Boolean,
        togglePopup: Function,
    },
    data: () => ({
        imgSrc: '',
    }),
    created() {},
    mounted() {},
    methods: {},
    watch: {
        data() {},
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.value {
    font-weight: bold;
    font-size: 22px !important;
    color: white;
    text-align: right;
    img {
        width: 24px;
        margin-right: 5px;
        margin-top: 5px;
        margin-bottom: -3px;
    }
}
.info-value {
    font-size: 14px;
    color: white;
    text-align: right;
}
.p_item {
    flex-direction: column;
    background: #f8f1ec;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin-bottom: 16px;
    overflow: hidden;
    transition: background 0.3s;

    .img_wrapper {
        width: 100%;
    }
    .detail_wrapper {
        width: 100%;
        text-align: center;
        padding: 12px;
        flex-direction: column;

        h5 {
            // color: $color_main_first;
            font-weight: bold;
            font-size: 20px;
            // margin-bottom: 4px;
        }
        h6 {
            color: $color_main_grey_dark;
            font-size: 16px;
            margin-bottom: 4px;
        }
        .due {
            color: $color_main_grey;
            font-size: 12px;
            line-height: 30px;
            background-image: url('~@/assets/images/ic24ArrowR.svg');
            background-repeat: no-repeat;
            background-position: right center;
            background-size: 8px;
        }
        &.done {
            background-image: url('~@/assets/images/ic_ticket.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
            .due {
                background: none;
            }
        }
        &.invalid {
            background-image: url('~@/assets/images/ic_ticket_expired.svg');
            background-repeat: no-repeat;
            background-position: right 15px top 32%;
        }
    }
    .green-background {
        background-image: url('~@/assets/images/bg_g@3x.png');
    }
    .orange-background {
        background-image: url('~@/assets/images/bg_o@3x.png');
    }
    * {
        font-size: 14px;
        line-height: 1.4;
    }

    &:active {
        background: #e9e0da;
    }
}
</style>
