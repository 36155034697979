var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"p_item d_flex"},[_c('div',{class:_vm.info.type == 'card'
                ? `img_wrapper orange-background`
                : `img_wrapper green-background`,staticStyle:{"background-size":"cover","height":"160px"}},[_c('div',{staticStyle:{"width":"100%","text-align":"right","padding-right":"20px","padding-top":"20px","margin-bottom":"20px"}},[_c('img',{staticStyle:{"width":"50px","float":"left","margin-left":"20px"},attrs:{"src":require("../assets/images/logo_L.png")}}),_c('p',{staticClass:"info-value",staticStyle:{"margin-bottom":"8px"}},[_vm._v(" 累積期限："+_vm._s(_vm.info.e_date)+" ")]),_c('p',{staticClass:"info-value"},[_vm._v("兌換期限："+_vm._s(_vm.info.ex_date))])]),_c('div',{staticClass:"row",staticStyle:{"padding-bottom":"20px"}},[_c('div',{staticClass:"col-4",staticStyle:{"padding-right":"20px"}},[_c('p',{staticClass:"info-value"},[_vm._v("消費金額")]),_c('p',{staticClass:"value"},[_c('img',{attrs:{"src":require("../assets/images/money.png")}}),_vm._v(_vm._s(_vm.info.amount)+" ")])]),_c('div',{staticClass:"col-4",staticStyle:{"padding-right":"20px"}},[_c('p',{staticClass:"info-value"},[_vm._v("累積點數")]),_c('p',{staticClass:"value"},[_c('img',{attrs:{"src":require("../assets/images/foot_active.png")}}),_vm._v(_vm._s(_vm.info.ac_point)),_c('label',{staticClass:"info-value"},[_vm._v("點")])])]),_c('div',{staticClass:"col-4",staticStyle:{"padding-right":"20px"}},[_c('p',{staticClass:"info-value"},[_vm._v("剩餘點數")]),_c('p',{staticClass:"value"},[_c('img',{attrs:{"src":require("../assets/images/foot_active.png")}}),_vm._v(_vm._s(_vm.info.rm_point)),_c('label',{staticClass:"info-value"},[_vm._v("點")])])])])]),_c('div',{staticClass:"detail_wrapper d_flex"},[_c('h5',{style:(_vm.info.type == 'card' ? 'color:#FF9F48;' : 'color:#808184;'),on:{"click":function($event){_vm.showPopup
                    ? _vm.togglePopup()
                    : _vm.$router.push(
                          '/point/coupon?id=' +
                              _vm.info.id +
                              '&point=' +
                              _vm.info.rm_point
                      )}}},[_vm._v(" 兌換優惠卷 ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }